import React from 'react';

function ax20(){
    return (
        <div className = "main">
            <h1>ax20</h1>
            <div className = "create-flex">
                <p>ax20 is an E1337 coder and hacker. Some say he has gotten records into a database!</p>
            </div>
        </div>
    );
}

export default ax20;