import React from 'react';

function Main(){
    return (
        <div className = "main">
            <h1>Welcome to NOTORIEX Profiles!</h1>
            <p>Nothing to see here at the moment!</p>
        </div>
    );
}

export default Main;