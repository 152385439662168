import React from 'react';
import Lex from '../img/2020_lexus-is-55255.jpg';

function entprestigious(){
    return (
        <div className = "main">
            <div className = "create-flex">
                <img src = {Lex} alt = "Image" title = "ELITE NOTORIOUS THE PRESTIGIOUS Avatar"/>
                <h1>ELITE NOTORIOUS THE PRESTIGIOUS</h1>
            </div>
            <p>ELITE NOTORIOUS THE PRESTIGIOUS is the OG of NTRX. They are feared amongst many! Some say that they have killed dozens of players in Westwood!</p>
        </div>
    );
}

export default entprestigious;