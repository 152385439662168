import React from 'react';
import {Link} from 'react-router-dom';

function Header(){
    return (
        <header>
            <Link to = '/'>NOTORIEX Profiles</Link>
        </header>
    );
}

export default Header;